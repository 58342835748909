import logo from './logo.svg';
import './styles/App.css';

import Routers from "./routes";

function App() {
  return <Routers />
}

export default App;
