import { useState, useEffect } from "react";
import { Container, Row, Col, Button, Tabs, Tab, Table, Nav } from "react-bootstrap";
import { Cart, Search } from 'react-bootstrap-icons';
import { useNavigate } from "react-router-dom";

import { Hosting, Domains, AdminSettings } from "../pages";

import 'animate.css';

export const DashboardTabs = () => {
  let navigate = useNavigate();
  const [key, setKey] = useState('Hosting');

  const [loopNum, setLoopNum] = useState(0);
  const [isDeleting, setIsDeleting] = useState(false);
  const [text, setText] = useState('');
  const [delta, setDelta] = useState(300 - Math.random() * 100);
  const [index, setIndex] = useState(1);
  const toRotate = ["Domain registration"," Your business online", "Be successful"];
  const period = 2000;
  const [formDetails, setFormDetails] = useState('');

  useEffect(() => {
    let ticker = setInterval(() => {
      tick();
    }, delta);

    return () => { clearInterval(ticker) };
  }, [text])

  const tick = () => {
    let i = loopNum % toRotate.length;
    let fullText = toRotate[i];
    let updatedText = isDeleting ? fullText.substring(0, text.length - 1) : fullText.substring(0, text.length + 1);

    setText(updatedText);

    if (isDeleting) {
      setDelta(prevDelta => prevDelta / 2);
    }

    if (!isDeleting && updatedText === fullText) {
      setIsDeleting(true);
      setIndex(prevIndex => prevIndex - 1);
      setDelta(period);
    } else if (isDeleting && updatedText === '') {
      setIsDeleting(false);
      setLoopNum(loopNum + 1);
      setIndex(1);
      setDelta(500);
    } else {
      setIndex(prevIndex => prevIndex + 1);
    }
  }

  const onFormUpdate = (category, value) => {
    setFormDetails({
      ...formDetails,
      [category]: value
    })
}

const menu = [
    {
      title: "Hosting",
      component: <Hosting />
    },
    {
      title: "Domains",
      component: <Domains />
    },
    // {
    //   title: "Integrations",
    //   description: "Integrations",
    // },
    // {
    //   title: "Activity",
    //   description: "Activity history",
    // },
    // {
    //     title: "Usage",
    //     description: "Usage and metrics",
    // },
    {
        title: "Admin settings",
        component: <AdminSettings />
    }
  ];

const onSearch = async (e) => {
  e.preventDefault();
  // setButtonText("Sending...");
  // let response = await fetch("http://localhost:5000/contact", {
  //   method: "POST",
  //   headers: {
  //     "Content-Type": "application/json;charset=utf-8",
  //   },
  //   body: JSON.stringify(formDetails),
  // });
  // setButtonText("Send");
  // let result = await response.json();
  // setFormDetails(formInitialDetails);
  // if (result.code === 200) {
  //   setStatus({ succes: true, message: 'Message sent successfully'});
  // } else {
  //   setStatus({ succes: false, message: 'Something went wrong, please try again later.'});
  // }
};

  return (
    <section className="domain" id="home">
      <Container>
        <Tabs variant="pills"
          id="controlled-tab-example"
          activeKey={key}
          onSelect={(k) => setKey(k)}
          >
          {
            menu.map((m, index) => (
              <Tab key={index} 
                    eventKey={m.title} 
                    title={m.title}
              >
                {m.component}
              </Tab>
            ))
          }
        </Tabs>
      </Container>
    </section>
  )
}
