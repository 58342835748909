import { NavBar, Banner, Skills, Projects, Contact, Footer } from "../../components";

export const Home = () => {
  return (
    <div className="App">
      <NavBar />
      <Banner />
      <Skills />
      <Projects />
      <Contact />
      <Footer />
    </div>
  );
}
