import { DashboardNavBar, DashboardTabs, DashboardFooter } from "./components";

export const Dashboard = () => {
  return (
    <div className="App">
      <DashboardNavBar />
      <DashboardTabs />
      <DashboardFooter />
    </div>
  );
}
