import React from "react";

import { Routes, Route } from "react-router-dom";

import { Home, Domains, Dashboard, Authentication } from "../pages";

const Routers = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/domains" element={<Domains />} />
      <Route path="/login" element={<Authentication />} />
      <Route path="/dashboard" element={<Dashboard />} />
    </Routes>
  );
};

export default Routers;