import React, { useState } from "react";
import { Navbar, Container } from "react-bootstrap";

import logo from '../../assets/img/logo.svg';
import { Login } from "./Login";
import { Register } from "./Register";

export const Authentication = () => {
  const [currentForm, setCurrentForm] = useState('login');

  const toggleForm = (formName) => {
    setCurrentForm(formName);
  }

  return (
    <div className="Authentication">
        <Navbar expand="md">
            <Container>
                <Navbar.Brand href="/">
                    <img src={logo} alt="Logo" />
                </Navbar.Brand>
            </Container>
            </Navbar>
      {
        currentForm === "login" ? <Login onFormSwitch={toggleForm} /> : <Register onFormSwitch={toggleForm} />
      }
    </div>
  );
}
