import { Container, Row } from "react-bootstrap";
import { ProjectCard } from "../../../../components";
import projImg1 from "../../../../assets/img/seed-plan.jpeg";
import projImg2 from "../../../../assets/img/startup-plan.jpg";
import projImg3 from "../../../../assets/img/business-plan.jpg";
import 'animate.css';

export const Hosting = () => {

  const projects = [
    {
      title: "Flembee",
      description: "Find a domain and boost your business",
      imgUrl: projImg1,
    },
    {
      title: "Kua",
      description: "Register your domain and get the right hosting",
      imgUrl: projImg2,
    },
    {
      title: "Benditos Paladares",
      description: "Be more professional and impress your customers by sending emails",
      imgUrl: projImg3,
    },
    {
      title: "Nebula Domain",
      description: "Find a domain and boost your business",
      imgUrl: projImg1,
    },
    {
      title: "Brunch and Meet",
      description: "Register your domain and get the right hosting",
      imgUrl: projImg2,
    },
    {
      title: "Wondersell",
      description: "Be more professional and impress your customers by sending emails",
      imgUrl: projImg3,
    }
  ];

  return (
    <section id="hosting">
      <Container style={{marginTop: 50}}>
        <h1>Your websites</h1>
        <Row style={{cursor: 'pointer', marginTop: 30}}>
          {
            projects.map((project, index) => {
              return (
                <ProjectCard
                  key={index}
                  {...project}
                  />
              )
            })
          }
        </Row>
      </Container>
    </section>
  )
}
