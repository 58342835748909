import { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import headerImg from "../assets/img/header-img.png";
import { ArrowRightCircle, Search } from 'react-bootstrap-icons';
import { useNavigate } from "react-router-dom";

import 'animate.css';

export const Banner = () => {
  let navigate = useNavigate();

  const [loopNum, setLoopNum] = useState(0);
  const [isDeleting, setIsDeleting] = useState(false);
  const [text, setText] = useState('');
  const [delta, setDelta] = useState(300 - Math.random() * 100);
  const [index, setIndex] = useState(1);
  const toRotate = ["Domain registration"," Your business online", "Be successful"];
  const period = 2000;
  const [formDetails, setFormDetails] = useState('');

  useEffect(() => {
    let ticker = setInterval(() => {
      tick();
    }, delta);

    return () => { clearInterval(ticker) };
  }, [text])

  const tick = () => {
    let i = loopNum % toRotate.length;
    let fullText = toRotate[i];
    let updatedText = isDeleting ? fullText.substring(0, text.length - 1) : fullText.substring(0, text.length + 1);

    setText(updatedText);

    if (isDeleting) {
      setDelta(prevDelta => prevDelta / 2);
    }

    if (!isDeleting && updatedText === fullText) {
      setIsDeleting(true);
      setIndex(prevIndex => prevIndex - 1);
      setDelta(period);
    } else if (isDeleting && updatedText === '') {
      setIsDeleting(false);
      setLoopNum(loopNum + 1);
      setIndex(1);
      setDelta(500);
    } else {
      setIndex(prevIndex => prevIndex + 1);
    }
  }

  const onFormUpdate = (category, value) => {
    setFormDetails({
      ...formDetails,
      [category]: value
    })
}

const onSearch = async (e) => {
  e.preventDefault();
  // setButtonText("Sending...");
  // let response = await fetch("http://localhost:5000/contact", {
  //   method: "POST",
  //   headers: {
  //     "Content-Type": "application/json;charset=utf-8",
  //   },
  //   body: JSON.stringify(formDetails),
  // });
  // setButtonText("Send");
  // let result = await response.json();
  // setFormDetails(formInitialDetails);
  // if (result.code === 200) {
  //   setStatus({ succes: true, message: 'Message sent successfully'});
  // } else {
  //   setStatus({ succes: false, message: 'Something went wrong, please try again later.'});
  // }
};

  return (
    <section className="banner" id="home">
      <Container>
        <Row className="aligh-items-center">
          <Col xs={12} md={6} xl={7}>
            <div>
              <form onSubmit={onSearch}>
                <div className="bannerInputWrapper">
                  <input 
                    type="text" 
                    value={formDetails.firstName} 
                    placeholder="Search a domain to start" 
                    onChange={(e) => onFormUpdate('firstName', e.target.value)} 
                  />
                  <Search className="bannerInputIcon" size={25} />
                </div>  
              </form> 
              <h1><span className="txt-rotate" data-period="1000" data-rotate='["Domain registration"," Your business online", "Be successful"]'><span className="wrap">{text}</span></span></h1>
                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                <button onClick={() => navigate('/login')}>Let’s start <ArrowRightCircle size={25} /></button>
            </div>
          </Col>
          <Col xs={12} md={6} xl={5}>
            <div>
              <img src={headerImg} alt="Header Img"/>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  )
}
