import { Container, Row, Col } from "react-bootstrap";
import { ProjectCard } from "./ProjectCard";
import projImg1 from "../assets/img/seed-plan.jpeg";
import projImg2 from "../assets/img/startup-plan.jpg";
import projImg3 from "../assets/img/business-plan.jpg";
import colorSharp2 from "../assets/img/color-sharp2.png";
import 'animate.css';

export const Projects = () => {

  const projects = [
    {
      title: "Seed Plan",
      description: "Find a domain and boost your business",
      imgUrl: projImg1,
    },
    {
      title: "Startup Plan",
      description: "Register your domain and get the right hosting",
      imgUrl: projImg2,
    },
    {
      title: "Business Plan",
      description: "Be more professional and impress your customers by sending emails",
      imgUrl: projImg3,
    }
  ];

  return (
    <section className="project" id="hosting">
      <Container>
        <Row>
          <Col size={12}>
            <div>
              <h2>Discover our plans</h2>
              <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
              <Row style={{cursor: 'pointer'}}>
                {
                  projects.map((project, index) => {
                    return (
                      <ProjectCard
                        key={index}
                        {...project}
                        />
                    )
                  })
                }
              </Row>
            </div>
          </Col>
        </Row>
      </Container>
      <img className="background-image-right" src={colorSharp2} alt="img"></img>
    </section>
  )
}
