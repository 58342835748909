import { useState, useEffect } from "react";
import { Container, Button, Tabs, Tab, Table, Nav } from "react-bootstrap";
import headerImg from "../assets/img/header-img.png";
import { Cart, Search } from 'react-bootstrap-icons';
import { useNavigate } from "react-router-dom";

import 'animate.css';

export const DomainsList = () => {
  let navigate = useNavigate();
  const [key, setKey] = useState('Popular');

  const [loopNum, setLoopNum] = useState(0);
  const [isDeleting, setIsDeleting] = useState(false);
  const [text, setText] = useState('');
  const [delta, setDelta] = useState(300 - Math.random() * 100);
  const [index, setIndex] = useState(1);
  const toRotate = ["Domain registration"," Your business online", "Be successful"];
  const period = 2000;
  const [formDetails, setFormDetails] = useState('');

  useEffect(() => {
    let ticker = setInterval(() => {
      tick();
    }, delta);

    return () => { clearInterval(ticker) };
  }, [text])

  const tick = () => {
    let i = loopNum % toRotate.length;
    let fullText = toRotate[i];
    let updatedText = isDeleting ? fullText.substring(0, text.length - 1) : fullText.substring(0, text.length + 1);

    setText(updatedText);

    if (isDeleting) {
      setDelta(prevDelta => prevDelta / 2);
    }

    if (!isDeleting && updatedText === fullText) {
      setIsDeleting(true);
      setIndex(prevIndex => prevIndex - 1);
      setDelta(period);
    } else if (isDeleting && updatedText === '') {
      setIsDeleting(false);
      setLoopNum(loopNum + 1);
      setIndex(1);
      setDelta(500);
    } else {
      setIndex(prevIndex => prevIndex + 1);
    }
  }

  const onFormUpdate = (category, value) => {
    setFormDetails({
      ...formDetails,
      [category]: value
    })
}

const categories = [
    {
        title: "Popular",
        description: "Find a domain and boost your business",
    },
    {
        title: "Education",
        description: "Be more professional and impress your customers by sending emails",
    },
    {
        title: "Finance",
        description: "Find a domain and boost your business",
    },
    {
        title: "Business",
        description: "Be more professional and impress your customers by sending emails",
    },
    {
        title: "Marketing",
        description: "Find a domain and boost your business",
    },
    {
        title: "Technology",
        description: "Register your domain and get the right hosting",
    },
    {
        title: "Organizations",
        description: "Find a domain and boost your business",
    },
    {
        title: "Shopping & Sales",
        description: "Register your domain and get the right hosting",
    },
    {
        title: "Handshake",
        description: "Be more professional and impress your customers by sending emails",
    },
    {
        title: "More...",
        description: "Be more professional and impress your customers by sending emails",
    },
    // {
    //     title: "Travel",
    //     description: "Be more professional and impress your customers by sending emails",
    // },
    // {
    //     title: "Professional",
    //     description: "Register your domain and get the right hosting",
    // },
    // {
    //     title: "Politics",
    //     description: "Register your domain and get the right hosting",
    // },
    // {
    //     title: "International",
    //     description: "Register your domain and get the right hosting",
    // },
    // {
    //     title: "Audio & Video",
    //     description: "Find a domain and boost your business",
    // },
    // {
    //     title: "Arts & Culture",
    //     description: "Register your domain and get the right hosting",
    // },
    // {
    //     title: "Adult",
    //     description: "Be more professional and impress your customers by sending emails",
    // },
    // {
    //     title: "Real Estate",
    //     description: "Be more professional and impress your customers by sending emails",
    // },
    // {
    //     title: "Short",
    //     description: "Find a domain and boost your business",
    // },
    // {
    //     title: "$2 or less",
    //     description: "Be more professional and impress your customers by sending emails",
    // },
    // {
    //     title: "Media & Music",
    //     description: "Be more professional and impress your customers by sending emails",
    // },
    // {
    //     title: "Fun",
    //     description: "Find a domain and boost your business",
    // },
    // {
    //     title: "Sports & Hobbies",
    //     description: "Register your domain and get the right hosting",
    // },
    // {
    //     title: "Products",
    //     description: "Be more professional and impress your customers by sending emails",
    // },
    // {
    //     title: "Transport",
    //     description: "Find a domain and boost your business",
    // },
    // {
    //     title: "New",
    //     description: "Register your domain and get the right hosting",
    // },
    // {
    //     title: "Personal",
    //     description: "Be more professional and impress your customers by sending emails",
    // },
    // {
    //     title: "Social & Lifestyle",
    //     description: "Find a domain and boost your business",
    // },
    // {
    //     title: "Food & Drink",
    //     description: "Register your domain and get the right hosting",
    // },
    // {
    //     title: "Services",
    //     description: "Be more professional and impress your customers by sending emails",
    // },
    // {
    //     title: "Beauty",
    //     description: "Find a domain and boost your business",
    // },
    // {
    //     title: "Cities",
    //     description: "Register your domain and get the right hosting",
    // },
    // {
    //     title: "Health & Fitness",
    //     description: "Find a domain and boost your business",
    // },
    // {
    //     title: "Colors",
    //     description: "Register your domain and get the right hosting",
    // },
    // {
    //     title: "Trades & Construction",
    //     description: "Be more professional and impress your customers by sending emails",
    // },
    // {
    //     title: "Non-English",
    //     description: "Find a domain and boost your business",
    // },
    // {
    //     title: "Religion",
    //     description: "Register your domain and get the right hosting",
    // },
    // {
    //     title: "Miscellaneous",
    //     description: "Be more professional and impress your customers by sending emails",
    // },
  ];

  const results = [
    {
        name: "nebula.com",
        price: "$9.02/yr",
    },
    {
        name: "nebula.net",
        price: "$10.00/yr",
    },
    {
        name: "nebula.io",
        price: "$18.00/yr",
    },
    {
        name: "nebula.app",
        price: "$23.05/yr",
    }
  ]

const onSearch = async (e) => {
  e.preventDefault();
  // setButtonText("Sending...");
  // let response = await fetch("http://localhost:5000/contact", {
  //   method: "POST",
  //   headers: {
  //     "Content-Type": "application/json;charset=utf-8",
  //   },
  //   body: JSON.stringify(formDetails),
  // });
  // setButtonText("Send");
  // let result = await response.json();
  // setFormDetails(formInitialDetails);
  // if (result.code === 200) {
  //   setStatus({ succes: true, message: 'Message sent successfully'});
  // } else {
  //   setStatus({ succes: false, message: 'Something went wrong, please try again later.'});
  // }
};

  return (
    <section className="domain" id="home">
      <Container>
        <form onSubmit={onSearch}>
        <div className="domainInputWrapper">
            <input 
            type="text" 
            value={formDetails.firstName} 
            placeholder="Search a domain to start" 
            onChange={(e) => onFormUpdate('firstName', e.target.value)} 
            />
            <Search className="domainInputIcon" size={25} />
        </div>  
        </form>
        <Tabs variant="pills"
            id="controlled-tab-example"
            activeKey={key}
            onSelect={(k) => setKey(k)}
            >
                {
                    categories.map((category, index) => (
                        <Tab key={index} 
                             eventKey={category.title} 
                             title={category.title}
                        >
                            <Table>
                                <thead>
                                    <tr>
                                        <th colSpan={3}>
                                            <h3 style={{paddingLeft: 26}}>Results</h3>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        results.map((result, k) => (
                                            <tr key={k}>
                                                <td>{result.name}</td>
                                                <td>{result.price}</td>
                                                <td>
                                                    <Button variant="secondary">
                                                        <Cart style={{marginRight: 10}}/>Buy
                                                    </Button>
                                                </td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </Table>
                        </Tab>
                    ))
                }
        </Tabs>
      </Container>
    </section>
  )
}
