import { Container, Row, Col } from "react-bootstrap";
import { MailchimpForm } from "./MailchimpForm";
import logo from "../assets/img/logo.svg";

import visa from "../assets/img/visa.png";
import mastercard from "../assets/img/mastercard.png";
import paypal from "../assets/img/paypal.png";
import bitcoin from "../assets/img/bitcoin.png";

import navIcon1 from "../assets/img/nav-icon1.svg";
import navIcon2 from "../assets/img/nav-icon2.svg";
import navIcon3 from "../assets/img/nav-icon3.svg";

export const Footer = () => {
  return (
    <footer className="footer">
      <Container>
        <Row className="align-items-center">
          <MailchimpForm />
            <Col size={12} sm={4}>
              <img src={logo} alt="Logo" />
            </Col>
            <Col size={12} sm={4} className="text-center">
              <p>Payment Options</p>
              <div className="payment-icon">
                <img src={visa} alt="Icon" />
                <img src={mastercard} alt="Icon" />
                <img src={paypal} alt="Icon" />
                <img src={bitcoin} alt="Icon" />
              </div>
            </Col>
            <Col size={12} sm={4} className="text-center text-sm-end">
              <div className="social-icon">
                <a href="#"><img src={navIcon1} alt="Icon" /></a>
                <a href="#"><img src={navIcon2} alt="Icon" /></a>
                <a href="#"><img src={navIcon3} alt="Icon" /></a>
              </div>
              <p>Copyright 2023. All Rights Reserved.</p>
              <p>Developed by <a style={{color: 'white'}} href="https://www.flembee.com" target='_blank' rel="noreferrer">Flembee</a></p>
            </Col>
        </Row>
      </Container>
    </footer>
  )
}
