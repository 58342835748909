import { useState, useEffect } from "react";
import { Navbar, Container } from "react-bootstrap";
import logo from '../../../assets/img/logo.svg';
import { useNavigate } from "react-router-dom";


export const DashboardNavBar = () => {
  let navigate = useNavigate();

  const [activeLink, setActiveLink] = useState('home');
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const onScroll = () => {
      if (window.scrollY > 50) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    }

    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  }, [])

  const onUpdateActiveLink = (value) => {
    setActiveLink(value);
  }

  return (
    <Navbar expand="md" className={scrolled ? "scrolled" : ""}>
      <Container>
        <Navbar.Brand href="/">
          <img src={logo} alt="Logo" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav">
          <span className="navbar-toggler-icon"></span>
        </Navbar.Toggle>
        <Navbar.Collapse id="basic-navbar-nav">
          <span className="navbar-text">
              <button 
                className="vvd"
                onClick={() => navigate('/login')}
              >
                <span>Login</span>
              </button>
          </span>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  )
}
