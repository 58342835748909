import { useState, useEffect } from "react";
import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import 'animate.css';

export const AdminSettings = () => {
  let navigate = useNavigate();

  const [key, setKey] = useState('General');

const menu = [
    {
        title: "General",
        component: ""
    },
    {
        title: "Users",
        component: ""
    },
    {
        title: "Billing",
        component: ""
    },
    {
        title: "Invoices",
        component: ""
    },
    {
        title: "My notifications",
        component: ""
    },
  ];

  return (
    <section id="admin-settings">
        <Container style={{marginTop: 50}}>
            <h1>Admin Account Settings</h1>
        <Tab.Container 
            id="left-tabs-example" 
            style={{marginTop: 30}}
            activeKey={key}
            onSelect={(k) => setKey(k)}
        >
          <Row style={{marginTop: 80}}>
            <Col sm={3}>
              <Nav variant="pills" className="flex-column">
                {
                    menu.map((m, index) => (
                        <Nav.Item key={index}>
                            <Nav.Link eventKey={m.title}>{m.title}</Nav.Link>
                        </Nav.Item>
                    ))
                }
              </Nav>
            </Col>
            <Col sm={9}>
              <Tab.Content>
                {
                    menu.map((m, index) => (
                        <Tab.Pane key={index} eventKey={m.title}>
                            {m.title}
                        </Tab.Pane>
                    ))
                }
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>
      </Container>
    </section>
  )
}
