import { NavBar, DomainsList, Footer } from "../../components";

export const Domains = () => {
  return (
    <div className="App">
      <NavBar />
      <DomainsList />
      <Footer />
    </div>
  );
}
