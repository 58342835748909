import { useState, useEffect } from "react";
import { Container, Table } from "react-bootstrap";
import { Search } from 'react-bootstrap-icons';
import { useNavigate } from "react-router-dom";

import 'animate.css';

export const Domains = () => {
  let navigate = useNavigate();

 const results = [
    {
        name: 'nebula.com',
        registrar: 'Third Party',
        nameServers: 'Third Party',
        status: 'active',
        expires: 'Jun 19, 2023'
    },
    {
        name: 'nebula.net',
        registrar: 'Third Party',
        nameServers: 'Third Party',
        status: 'active',
        expires: 'May 23, 2023'
    },
    {
        name: 'nebula.io',
        registrar: 'Third Party',
        nameServers: 'Third Party',
        status: 'active',
        expires: 'Aug 02, 2023'
    },
    {
        name: 'nebula.app',
        registrar: 'Third Party',
        nameServers: 'Third Party',
        status: 'active',
        expires: 'Set 08, 2023'
    }
  ]

const onSearch = async (e) => {
  e.preventDefault();
  // setButtonText("Sending...");
  // let response = await fetch("http://localhost:5000/contact", {
  //   method: "POST",
  //   headers: {
  //     "Content-Type": "application/json;charset=utf-8",
  //   },
  //   body: JSON.stringify(formDetails),
  // });
  // setButtonText("Send");
  // let result = await response.json();
  // setFormDetails(formInitialDetails);
  // if (result.code === 200) {
  //   setStatus({ succes: true, message: 'Message sent successfully'});
  // } else {
  //   setStatus({ succes: false, message: 'Something went wrong, please try again later.'});
  // }
};

  return (
    <section id="dashboard-domain">
      <Container style={{marginTop: 50}}>
        <h1>Your domains</h1>
        <Table style={{marginTop: 30}}>
            <thead>
            <tr>
                <th>Domain</th>
                <th>Registrar</th>
                <th>Name servers</th>
                <th>Renewal status</th>
                <th>Expires on</th>
            </tr>
            </thead>
            <tbody style={{textAlign: 'start'}}>
            {
                results.map((result, k) => (
                    <tr key={k}>
                        <td>{result.name}</td>
                        <td>{result.registrar}</td>
                        <td>{result.nameServers}</td>
                        <td>{result.status}</td>
                        <td>{result.expires}</td>
                    </tr>
                ))
            }
            </tbody>
        </Table>
      </Container>
    </section>
  )
}
